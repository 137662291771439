import React, { useState } from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FormReserveYourSpot } from "./FormReserveYourSpot/FormReserveYourSpot";
import { ShareSection } from "./ShareSection/ShareSection";

import calendar from "../../../assets/images/calendar.png";

import col from "../../../assets/images/social-networks/col.png";
import bra from "../../../assets/images/social-networks/bra.png";
import mex from "../../../assets/images/social-networks/mex.png";
import chi from "../../../assets/images/social-networks/chi.png";
import ecu from "../../../assets/images/social-networks/ecu.png";

export const SectionUpcomingWebinars = () => {

	const [seeMore, isSeeMore] = useState(false)
	const [signUp, setSignUp] = useState(false)
	const [share, isShare] = useState(false)

	const dummyHashtag = [
		{ id: 1, name: "Tecnología" },
		{ id: 2, name: "Tracking" },
		{ id: 3, name: "Transporte" },
	]

	const dummyList = [
		{ id: 1, text: "Desempeño en tiempos de crisis, caso Liftit." },
		{ id: 2, text: "Eficiencia financiera interna." },
		{ id: 3, text: "Rentabilidad." },
		{ id: 4, text: "Ciclos de flujo de caja efectivos." },
		{ id: 5, text: "Estrategias de generación de ahorro para clientes." },
	]

	const dummyCitys = [
		{ id: 1, src: col },
		{ id: 2, src: bra },
		{ id: 3, src: chi },
		{ id: 4, src: mex },
		{ id: 5, src: ecu },
	]

	return (
		<div className="section-upcoming-webinars">
			<div className="section-upcoming-webinars__container">
				<div className="head">
					<h2 className="head--title">Próximos webinars</h2>
					<div className="btns">
						{dummyHashtag?.map((item, index) =>
							<Button key={index}>#{item.name}</Button>
						)}
					</div>
				</div>
				{share &&
					<ShareSection dummyCitys={dummyCitys} isSeeMore={isSeeMore} isShare={isShare}/>
				}
				{!share &&
					<>
						{!seeMore &&
							<div className="webinars animate__fadeIn animate__animated">
								<div className="webinars--box webinars--left">
									<div className="webinars--box__text">
										<div>
											<img src="https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/webinaars1.png" className="webinars--imagen" />
											<h3>Andrés Coronado</h3>
											<h6>Gerente Nacional de Ventas</h6>
										</div>
										<div className="cont-btn">
											<Button className="btn btn--demo" onClick={() => isShare(true)}>
												Share
										</Button>
										</div>
									</div>
									<div className="webinars--box__text">
										<div>
											<h2>Webinar</h2>
											<p>Tecnología y transporte, tu mejor aliado en la operación logística</p>
										</div>
										<div className="cont-btn">
											<Button className="btn btn--primary" onClick={() => isSeeMore(true)}>Ver info</Button>
										</div>
									</div>
								</div>
								<div className="webinars--box webinars--right">
									<div className="webinars--box__text">
										<div>
											<img src="https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/webinars2.png" className="webinars--imagen" />
											<h3>David Sandoval</h3>
											<h6>Head Of Financial Planning & Analysis</h6>
										</div>
										<div className="cont-btn">
											<Button className="btn btn--demo" onClick={() => isShare(true)}>
												Share
										</Button>
										</div>
									</div>
									<div className="webinars--box__text">
										<div>
											<h2>Webinar</h2>
											<p>Innovación tecnológica en la centralización y el tracking financiero ¡Mejora tu rentabilidad y flujo de caja!</p>
										</div>
										<div className="cont-btn">
											<Button className="btn btn--primary" onClick={() => isSeeMore(true)}>Ver info</Button>
										</div>
									</div>
								</div>
							</div>
						}
						{seeMore &&
							<div className="after animate__fadeIn animate__animated">
								<div className="after--left">
									<div className="cont-img">
										<img src="https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/webinaars1.png" className="webinars--imagen" />
									</div>
									<div className="text">
										<h3>Andrés Coronado</h3>
										<h6>Gerente Nacional de Ventas</h6>
									</div>
								</div>
								<div className="after--right">
									<div className="after--right__left">
										<h2>Webinar</h2>
										<p>
											Innovación tecnológica en la centralización y el tracking financiero ¡Mejora tu rentabilidad y flujo de caja!
									</p>
										<ul className="list">
											{dummyList.map((item, index) =>
												<li className="list__item" key={index}>{item.text}</li>
											)}
										</ul>
									</div>
									<div className="after--right__right">
										<div className="cont-btn">
											<Button className="btn btn--demo">Share</Button>
											<Button className="close" onClick={() => isSeeMore(false)}><CloseOutlined /></Button>
										</div>
										{!signUp &&
											<>
												<div className="cont-calendar">
													<img className="calendar" src={calendar} />
													<p>Lorem ipsum</p>
												</div>
												<div className="citys">
													{dummyCitys?.map((item, index) =>
														<div key={index} className="citys__item">
															<img src={item.src} alt="imagen" className="img" />
															<p>00:00 Hrs</p>
														</div>
													)}
												</div>
												<div className="cont-btn">
													<Button className="btn btn--primary" onClick={() => setSignUp(true)}>Inscribete</Button>
												</div>
											</>
										}
										{signUp &&
											<FormReserveYourSpot signUp={signUp} setSignUp={setSignUp} />
										}
									</div>
								</div>
							</div>
						}
					</>
				}
			</div>
		</div>
	);
}