import React from "react";
import menu from "../../../assets/images/men.png";
import { Button } from "antd";
import Slider from "react-slick";

import comment from "../../../assets/images/comment.png";
import like from "../../../assets/images/like-gray.png";

export const SectionPastWebinars = () => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
		]
	};
	const dummyCards = [
		{id:1,name:"Andrés Coronado",src:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/card1.png",date:"Julio 30, 2020",text:"Tecnología y transporte, tu mejor aliado en la operación logística",profile:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/avatar%20(2).png"},
		{id:2,name:"David Sandoval",src:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/card2.png",date:"Julio 30, 2020",text:"Innovación tecnológica en la centralización y el tracking financiero ¡Mejora tu rentabilidad y flujo de caja!",profile:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/avatar%20(2).png"},
		{id:3,name:"David Sandoval",src:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/card2.png",date:"Julio 30, 2020",text:"Innovación tecnológica en la centralización y el tracking financiero ¡Mejora tu rentabilidad y flujo de caja!",profile:"https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/avatar%20(2).png"},
	]
	return (
		<div className="SectionPastWebinars">
			<div className="SectionPastWebinars__container">
				<div className="head">
					<h1>Webinars pasados</h1>
					<Button className="btn-menu">
						<img src={menu} alt="menu" />
					</Button>
				</div>
				<div className="caroucel">
					<Slider {...settings}>
						{dummyCards?.map((item,index)=>
							<div className="card" key={index} >
								<div className="content" style={{backgroundImage:`url(${item.src})`}}>
									<div className="text">
										<h3>{item.date}</h3>
										<p>{item.text}</p>
									</div>
									<div className="cont-btn">
										<Button className="btn btn--demo">Ver más</Button>
									</div>
								</div>
								<div className="comment">
									<div>
										<img className="profile" alt="profile" src={item.profile}/>
										<h4>{item.name}</h4>
									</div>
									<div>
										<Button>
											<img className="icon" alt="icon" src={comment}/>
										</Button>
										<Button>
											<img className="icon" alt="icon" src={like}/>
										</Button>
									</div>
								</div>
							</div>
						)}
					</Slider>
				</div>
			</div>
		</div>
	);
}