import React from "react";
import { Form, Input, Button, Checkbox } from 'antd';

export const SectionContact = () => {

	const onFinish = values => {
    console.log('Success:', values);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
	};
	
	return (
		<div className="SectionContact">
			<div className="SectionContact__container">
				<div className="left">
					<h1>Suscribete a nuestro <span>Newsletter!</span></h1>
					<p>Suscribete para que recibas tu dosis semanal de contenido directamente en tu bandeja de correo.</p>
				</div>
				<div className="right">
					<Form
						name="basic"
						initialValues={{ remember: true }}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>
						<div className="form">
							<Form.Item
								className="input"
								name="username"
								rules={[{ required: true, message: 'Please input your username!' }]}
							>
								<Input />
							</Form.Item>
							<Form.Item className="cont-btn">
								<Button className="btn btn--primary" htmlType="submit">
									Suscribete
								</Button>
							</Form.Item>
						</div>
						<Form.Item  name="remember">
							<Checkbox>Al suscribirte aceptas las Politicas de Privacidad</Checkbox>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	);
}