import { Button } from "antd";
import React, { useState } from "react";
import calendar from "../../../../assets/images/calendar.png";
import { ProfileWebinar } from "../ProfileWebinar/ProfileWebinar";

export const ShareSection = ({ dummyCitys, isSeeMore, isShare }) => {

	const [seeInformation, setSeeInformation] = useState(false);
	const [itemSelcet , setItemSelcet] = useState();

	const dummyUsersWebinars = [
		{ id: 1, name: "Andrés Coronado", rol: "Gerente Nacional de Ventas", description: "Tecnología y transporte, tu mejor aliado en la operación logística", date: "8:30 / 9:30", src: "https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/h1.png",
		itemsPersonal : [
			{id:1 , text:"Desempeño en tiempos de crisis, caso Liftit." , time:"00:00 - 00:00 Hrs"},
			{id:2 , text:"Eficiencia financiera interna." , time:"00:00 - 00:00 Hrs"},
			{id:3 , text:"Rentabilidad." , time:"00:00 - 00:00 Hrs"},
			{id:4 , text:"Ciclos de flujo de caja efectivos." , time:"00:00 - 00:00 Hrs"},
			{id:5 , text:"Estrategias de generación de ahorro para clientes." , time:"00:00 - 00:00 Hrs"},
		] },
		{ id: 2, name: "David Sandoval", rol: "Head Of Financial Planning & Analysis", description: "Innovación tecnológica en la centralización y el tracking financiero ¡Mejora tu rentabilidad y flujo de caja!", date: "9:30 / 10:30", src: "https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/h2.png" ,
		itemsPersonal : [
			{id:1 , text:"Desempeño en tiempos de crisis, caso Liftit." , time:"00:00 - 00:00 Hrs"},
			{id:2 , text:"Eficiencia financiera interna." , time:"00:00 - 00:00 Hrs"},
			{id:3 , text:"Rentabilidad." , time:"00:00 - 00:00 Hrs"},
			{id:4 , text:"Ciclos de flujo de caja efectivos." , time:"00:00 - 00:00 Hrs"},
			{id:5 , text:"Estrategias de generación de ahorro para clientes." , time:"00:00 - 00:00 Hrs"},
		]},
		{ id: 3, name: "Andrés Coronado ", rol: "Gerente Nacional de Ventas", description: "Tecnología y transporte, tu mejor aliado en la operación logística", date: "10:30 / 11:30", src: "https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/h3.png" ,
		itemsPersonal : [
			{id:1 , text:"Desempeño en tiempos de crisis, caso Liftit." , time:"00:00 - 00:00 Hrs"},
			{id:2 , text:"Eficiencia financiera interna." , time:"00:00 - 00:00 Hrs"},
			{id:3 , text:"Rentabilidad." , time:"00:00 - 00:00 Hrs"},
			{id:4 , text:"Ciclos de flujo de caja efectivos." , time:"00:00 - 00:00 Hrs"},
			{id:5 , text:"Estrategias de generación de ahorro para clientes." , time:"00:00 - 00:00 Hrs"},
		]},
	]

	const handleSeeMore = (item) => {
		setSeeInformation(true)
		setItemSelcet(item)
	}

	return (
		<div className="ShareSection">
			{!seeInformation &&
				<>
					<div className="ShareSection--body">
						{dummyUsersWebinars?.map((item, index) =>
							<div key={index} className="ShareSection--body--card">
								<img alt="profile" className="profile" src={item.src} />
								<div className="title">
									<h1>{item.name}</h1>
									<p>{item.rol}</p>
								</div>
								<p>{item.description}</p>
								<div className="date">
									<h3>Duración:</h3>
									<h1>{item.date}</h1>
								</div>
								<div className="cont-btn">
									<Button className="btn btn--primary" onClick={()=>handleSeeMore(item)}>Ver info</Button>
								</div>
							</div>
						)}
					</div>
					<div className="ShareSection--footer">
						<div className="calendar-cont">
							<img className="calendar" alt="calendar" src={calendar} />
							<p>lorem Ipsum</p>
						</div>
						<div className="cont-citys">
							{dummyCitys?.map((item, index) =>
								<div key={index} className="citys__item">
									<img src={item.src} alt="imagen" className="img" />
									<p>00:00 Hrs</p>
								</div>
							)}
						</div>
					</div>
				</>
			}
			{seeInformation &&
				<ProfileWebinar itemSelcet={itemSelcet} calendar={calendar} dummyCitys={dummyCitys}/>
			}
		</div>
	);
}