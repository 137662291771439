import React, { useState } from "react"
import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"

import { SectionBanner } from "../../components/PageWebinars/SectionBanner/SectionBanner";
import { SectionHeaderWebinars } from "../../components/PageWebinars/SectionHeaderWebinars/SectionHeaderWebinars";
import { SectionUpcomingWebinars } from "../../components/PageWebinars/SectionUpcomingWebinars/SectionUpcomingWebinars";
import { SectionPastWebinars } from "../../components/PageWebinars/SectionPastWebinars/SectionPastWebinars";
import { PreviewBlog } from "../../components/PreviewBlog/PreviewBlog";
import { SectionContact } from "../../components/PageWebinars/SectionContact/SectionContact";
const Webinars = () => {

	const [itemSelect, setItemSelect] = useState("Proximos");

	const dummyMenu = [
		{ id: 1, name: "Proximos" },
		{ id: 2, name: "Anteriores" },
		{ id: 3, name: "Todos" },
		{ id: 4, name: "Recursos" },
	]

	return (
		<Layout section="section_ligth">
			<div className="Webinars">
				<SectionBanner
					title={"¡Bienvenidos a nuestros"}
					Webinars={"Webinars!"}
					text={"Descubre cómo estamos transformando la logística en Latinoamérica."}
					img={"https://storage.googleapis.com/assets-contentful/development/v1/operadores/webinars/img-banner-webinars-min.png"}
				/>
				<SectionHeaderWebinars
					itemSelect={itemSelect}
					setItemSelect={setItemSelect}
					dummyMenu={dummyMenu}
				/>
				<SectionUpcomingWebinars />
				<SectionPastWebinars/>
				{/* <PreviewBlog/> */}
				<SectionContact/>
			</div>
		</Layout>
	)
}



export default Webinars