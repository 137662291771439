import React from "react";

export const SectionBanner = ({title,text,img,Webinars}) => {
	return (
		<div className="section-banner-webinars">
			<div className="section-banner-webinars__container">
				<div className="content">
					<h4>Hola</h4>
					<h1>{title} <span>{Webinars}</span></h1>
					<p>{text}</p>
				</div>
				<div className="content">
					<img src={img} alt="webinars" className="imagen"/>
				</div>
			</div>
		</div>
	)
}