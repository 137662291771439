import React from "react";
import { Form, Input, Button, Select  } from 'antd';
import {CloseOutlined} from '@ant-design/icons';

const { Option } = Select;

export const FormReserveYourSpot = ({signUp,  setSignUp}) => {

	const onFinish = values => {
		console.log('Success:', values);
	};

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo);
	};

	const handleChange = (value) => {
		console.log(`selected ${value}`);
	}

	return (
		<div className="FormReserveYourSpot">
			<div>
				<Form
					className="form"
					name="basic"
					initialValues={{ remember: true }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
				>
					<div className="head">
						<h2>Reserva tu lugar</h2>
						<Button className="close" onClick={()=>setSignUp(false)}><CloseOutlined /></Button>
					</div>
					<div className="form--item">
						<label>Nombre</label>
						<Form.Item
							name="name"
							rules={[{ required: true, message: 'Please input your username!' }]}
						>
							<Input />
						</Form.Item>
					</div>
					<div className="form--item">
						<label>Cargo</label>
						<Form.Item
							name="rol"
							rules={[{ required: true, message: 'Please input your username!' }]}
						>
							<Input />
						</Form.Item>
					</div>
					<div className="form--item">
						<label>¿En qué rol te identificas?</label>
						<Form.Item
							name="rol2"
							rules={[{ required: true, message: 'Please input your username!' }]}
						>
							<Select defaultValue="lucy" style={{ width: "100%" }} onChange={handleChange}>
								<Option value="jack">Jack</Option>
								<Option value="lucy">Lucy</Option>
								<Option value="disabled" disabled>
									Disabled
								</Option>
								<Option value="Yiminghe">yiminghe</Option>
							</Select>
						</Form.Item>
					</div>
					<div className="form--item">
						<label>Nombre de tu empresa</label>
						<Form.Item
							name="nameCompany"
							rules={[{ required: true, message: 'Please input your username!' }]}
						>
							<Input />
						</Form.Item>
					</div>
					<div className="form--item">
						<label>¿En qué ciudad te encuentras?</label>
						<Form.Item
							name="city"
							rules={[{ required: true, message: 'Please input your username!' }]}
						>
							<Input />
						</Form.Item>
					</div>
					<div className="form--item">
						<label>¿Cuántos vehículos asociados tienes?</label>
						<Form.Item
							name="car"
							rules={[{ required: true, message: 'Please input your username!' }]}
						>
							<Select defaultValue="lucy" style={{ width: "100%" }} onChange={handleChange}>
								<Option value="jack">Jack</Option>
								<Option value="lucy">Lucy</Option>
								<Option value="disabled" disabled>
									Disabled
								</Option>
								<Option value="Yiminghe">yiminghe</Option>
							</Select>
						</Form.Item>
					</div>
					<div className="form--item">
						<label>¿Qué servicios son de tu interés?</label>
						<Form.Item
							name="interes"
							rules={[{ required: true, message: 'Please input your username!' }]}
						>
							<Select defaultValue="lucy" style={{ width: "100%" }} onChange={handleChange}>
								<Option value="jack">Jack</Option>
								<Option value="lucy">Lucy</Option>
								<Option value="disabled" disabled>
									Disabled
								</Option>
								<Option value="Yiminghe">yiminghe</Option>
							</Select>
						</Form.Item>
					</div>

					<Form.Item className="cont-btn">
						<Button className="btn btn--primary" htmlType="submit">
							Inscribete
        		</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
}