import React from "react";
import { Button } from "antd";

export const ProfileWebinar = ({ itemSelcet, calendar, dummyCitys }) => {

	return (
		<div className="ProfileWebinar">
			<div className="ProfileWebinar--left">
				<img src={itemSelcet.src} className="profile" alt="profile" />
				<div className="pad">
					<h1>{itemSelcet.name}</h1>
					<p>{itemSelcet.rol}</p>
					<div className="cont-cal">
						<img src={calendar} alt="calendar" className="calendar" />
						<p>lorem Ipsum</p>
					</div>
				</div>
			</div>
			<div className="ProfileWebinar--right">
				<h1>Webinar</h1>
				<p>Innovación tecnológica en la centralización y el tracking financiero ¡Mejora tu rentabilidad y flujo de caja!</p>
				<ul className="list">
					{itemSelcet.itemsPersonal?.map((item, index) =>
						<li className="item" key={index}>
							<div>
								<span className="point">.</span>
								<span>{item.text}</span>
							</div>
							<span>{item.time}</span>
						</li>
					)}
				</ul>
				<div className="foo">
					<div className="cont-citys">
						{dummyCitys?.map((item, index) =>
							<div key={index} className="citys__item">
								<img src={item.src} alt="imagen" className="img" />
								<p>00:00 Hrs</p>
							</div>
						)}
					</div>
					<div className="cont-btn">
						<Button className="btn btn--demo">Share</Button>
						<Button className="btn btn--primary">Inscribete</Button>
					</div>
				</div>
			</div>
		</div>
	);
}