import React from "react";
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons'

export const SectionHeaderWebinars = ({itemSelect , setItemSelect , dummyMenu}) => {

	return (
		<div className="section-header-webinars">
			<div className="section-header-webinars__container">
				<ul className="list">
					{dummyMenu?.map((item,index)=>
						<li key={index} 
							className={`list--item  ${itemSelect === item.name && "list--item_active"}`}
							onClick={()=>setItemSelect(item.name)}>
							<a>{item.name}</a>
						</li>
					)}
				</ul>
				<div className="search">
					<Input
						placeholder="Buscar..."
						prefix={<SearchOutlined className="site-form-item-icon" />}
					/>
				</div>
			</div>
		</div>
	);
} 